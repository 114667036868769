// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-velocity-banking-car-tsx": () => import("./../src/pages/velocity-banking-car.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-car-tsx" */),
  "component---src-pages-velocity-banking-explained-tsx": () => import("./../src/pages/velocity-banking-explained.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-explained-tsx" */),
  "component---src-pages-velocity-banking-explained-visually-tsx": () => import("./../src/pages/velocity-banking-explained-visually.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-explained-visually-tsx" */),
  "component---src-pages-velocity-banking-tsx": () => import("./../src/pages/velocity-banking.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-tsx" */),
  "component---src-pages-velocity-banking-with-a-credit-card-tsx": () => import("./../src/pages/velocity-banking-with-a-credit-card.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-with-a-credit-card-tsx" */),
  "component---src-pages-velocity-banking-with-a-heloc-tsx": () => import("./../src/pages/velocity-banking-with-a-heloc.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-with-a-heloc-tsx" */),
  "component---src-pages-velocity-banking-with-a-personal-loan-tsx": () => import("./../src/pages/velocity-banking-with-a-personal-loan.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-with-a-personal-loan-tsx" */),
  "component---src-pages-velocity-banking-with-block-fi-tsx": () => import("./../src/pages/velocity-banking-with-block-fi.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-with-block-fi-tsx" */),
  "component---src-pages-velocity-banking-with-celcius-network-tsx": () => import("./../src/pages/velocity-banking-with-celcius-network.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-with-celcius-network-tsx" */),
  "component---src-pages-velocity-banking-with-cred-tsx": () => import("./../src/pages/velocity-banking-with-cred.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-with-cred-tsx" */),
  "component---src-pages-velocity-banking-with-cryptocurrency-tsx": () => import("./../src/pages/velocity-banking-with-cryptocurrency.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-with-cryptocurrency-tsx" */),
  "component---src-pages-velocity-banking-with-salt-tsx": () => import("./../src/pages/velocity-banking-with-salt.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-with-salt-tsx" */),
  "component---src-pages-velocity-banking-with-youhodler-tsx": () => import("./../src/pages/velocity-banking-with-youhodler.tsx" /* webpackChunkName: "component---src-pages-velocity-banking-with-youhodler-tsx" */)
}

